html,body{
  margin: 0;
}

body {
  -webkit-text-size-adjust: 100%;
}

.App {
  text-align: center;
}

.largeImage{
        height: 60%;
				width: 90%;
				align-self: left;
				float: center;
				padding-bottom: 2%;
}

.medlargeImage{
  height: 40%;
  width: 90%;
  align-self: left;
  float: center;
}

.smallImage{
  height: 40%;
  width: 40%;
}


.mediumImage{
  height: 60%;
  width: 40%;
}


.titleheader{
  font-size: 80px;
}

.introButton{
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.8);
  border: .2em solid;
  border-radius: 15%;
  color: rgba(255, 255, 255, 1);;
  text-align: center;
  font-size: 90%;

  width: 100%;
  height: 30%;
  transition: all 0.5s;
  cursor: pointer;
  vertical-align:middle;
  font-family: 'Roboto', sans-serif;
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);


}


.introButton span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}


.introButton:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  background-color: #ffffff;

}

.introButton:hover span:after {
  opacity: 1;
  right: 0;
}

.introButton:Link{
  text-decoration: none;
  font-family: 'Roboto', sans-serif;


}



Link, Link:visited{
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  color:rgb(41, 38, 38);

}

.aboutButton{
  display: inline-block;
  border-radius: 2vm;
  background-color: rgba(20, 22, 22, 0.05);
  border: .1em solid;
  color: rgba(20, 22, 22, 0.1);
  text-align: center;
  align-self: center;
  font-size: 100%;
  padding: .5em;
  width: 80%;
  height: 20%;
  text-align: center;
  transition: all 0.5s;
  cursor: pointer;
  vertical-align:middle
}


.aboutButton span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}


.aboutButton:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  background-color: #fafafa;

}

.aboutButton:hover span:after {
  opacity: 1;
  right: 0;
}

.aboutButton:Link{
  text-decoration: none;  

}


.continueButton{
  display: inline-block;
  border-radius: .7em;
  background-color: #f4511e;
  border: .1em solid;
  color: #F2B47E;
  text-align: center;
  font-size: 200%;
  padding: .5em;
  transition: all 0.5s;
  cursor: pointer;
  vertical-align:middle;
  width: 3vm;
  font-family: 'Roboto', sans-serif;
  width: 70%;
}


.continueButton span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}


.continueButton:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  background-color: #f8a963;

}

.continueButton:hover span:after {
  opacity: 1;
  right: 0;
}

.continueButton:Link{
  text-decoration: none;  
  color:rgb(41, 38, 38);

}

a:Link{
  text-decoration: none;
  color: rgb(48, 47, 47);

}

a:visited{
  text-decoration: none;
  color:rgb(34, 33, 33);

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.simple-trans-main > div {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: 800;
  font-size: 50%;
  font-family: 'Roboto', sans-serif;
  will-change: transform, opacity;
  
}


.fade-in{
  transition: opacity 1s ease;
}

.fade-out{
  opacity: 0;
  transition: opacity 1s ease;
}

.fadeIn {
  transition: opacity 1s ease-in-out;
  opacity: 0;
}
.fadeIn.enter {
  transition: opacity 1s ease-in-out;
  opacity: 1;
}
.slideLeft {
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  opacity: 0;
  transform: translateX(100%);
}
.slideLeft.enter {
  transition: opacity 1s ease-in-out, transform 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1;
  transform: translateX(0);
}
.slideUp {
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  opacity: 0;
  transform: translateY(100%);
}
.slideUp.enter {
  transition: opacity 1s ease-in-out, transform 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1;
  transform: translateY(0);
}
@media (print), (prefers-reduced-motion: reduce) {
  .fadeIn,
  .slideLeft,
  .slideUp {
    transition: none;
    opacity: 1;
    transform: none;
  }
}



.newsphoto {
  background-image: url(https://www.clipartmax.com/png/middle/17-172990_free-news-transparent-newspaper-clip-art.png);
}

.correct {
  border: 3px solid green;
  pointer-events: none;
}

.incorrect {
  border: 3px solid red;
  pointer-events: none;
  text-decoration: line-through;
}

.clear {
  pointer-events: none;
}

.multiple-choices {
  animation: pop-in 1s ease-out;
}

@keyframes pop-in {
  0% {
    transform: scale(0) translateY(-100px);
    opacity: 0;
  }

  80% {
    transform: scale(1.05) translateY(10px);
    opacity: 1;
  }

  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}

/* media queries */
@media only screen and (min-width: 320px) {
  ol {
    padding: 0 1rem;
    
    
  }
  .QuestionChoice:hover{
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  }
  .QuestionChoice{
    padding-bottom: 3.5%;
    padding-top: 3.5%;
    font-size: 300%;

  }
  p{
    font-size: 90%;
  }
  
}

@media only screen and (min-width: 768px) {
  ol {
    padding: 0 10rem;
  }
  .next-btn {
    font-size: 2rem;
  }
}

@media only screen and (min-width> 1200px) {
  ol {
    padding: 0 20rem;
  }
  .next-btn {
    font-size: 3rem;
  }
}

p{
  font-size: 100%;
  font-family: 'Roboto', sans-serif;
  color: #292b2a;


}

h1{
  font-size: 300%;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  color: #292b2a;



}

h2{
  font-size: 200%;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  color: #292b2a;

}

@media screen and (max-width : 700px) {
  h2 {
    font-size: 100%;
    font-family: 'Roboto', serif;

  }

  h3 {
    font-size: 100%;
    font-family: 'Roboto', sans-serif;
    text-align: "left"

  }

  h1 {
    font-size: 100%;
    font-family: 'Roboto', sans-serif;

  }

  p{
    font-size: 50%;
    font-family: 'Roboto', sans-serif;

  }

  h4{
    font-size: 100%;
    font-family: 'Roboto', sans-serif;

  }
}

@media (min-width > 700px) and (max-width < 1200px) {
  h2 {
    font-size: 100%;
    font-family: 'Roboto', serif;

  }

  h3 {
    font-size: 100%;
    font-family: 'Roboto', sans-serif;
    text-align: left;

  }

  h1 {
    font-size: 100%;
    font-family: 'Roboto', sans-serif;

  }

  p{
    font-size: 100%;
    font-family: 'Roboto', sans-serif;

  }

  h4{
    font-size: 60%;
    font-family: 'Roboto', sans-serif;

  }
}

h3{
  font-size: 100%;
  font-family: 'Roboto', sans-serif;
  text-align: left;
  

}
.navigation-wrapper {
  position: relative;
}

.dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
}

.dot {
  border: none;
  width: 10px;
  height: 10px;
  background: #c5c5c5;
  border-radius: 50%;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
}

.dot:focus {
  outline: none;
}

.dot.active {
  background: #000;
}

.arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: #fff;
  cursor: pointer;
}

.arrow--left {
  left: 50%;
}

.arrow--right {
  left: auto;
  right: 5%;

}

.arrow--disabled {
  fill: rgba(255, 255, 255, 0.5);
}

.slider-container {
  height: 100%;
  position: absolute;
  }


 /*** ACCORDION ***/

/* Header */
.accordion > div > div:first-of-type {
  padding: 1em;
  background-color: #f4511e;
  color: black;
  text-transform: uppercase;
  user-select: none;
  cursor: pointer;
  border-radius: 0.5em;
  box-shadow: 0 -10px 30px 2px rgba(0, 0, 0, 0.1) inset;
  font-size: 100%;
  font-family: 'Roboto', sans-serif;
  text-align: left;
  font-weight: bold;

}
.accordion > div > div:first-of-type:hover {
  background-color: #f8744c;
}

/* Content */
.accordion > div > div:last-of-type {
  background-color: #f9f9f9;
  margin: 0 0.5em;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.2);
}

/* Inner */
.accordion .accordion-item {
  padding: 1em;
  opacity: 0;
  transition: opacity 300ms ease;
  overflow: auto;
}
.accordion > div:first-child .accordion-item {
  max-height: 200px;
}
.accordion > div.open .accordion-item {
  opacity: 1;
  transition: opacity 700ms ease;
}
.accordion img.accordion-item {
  padding: 0;
  margin: 0;
  width: 100%;
}