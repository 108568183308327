.bm-item{
    display: inline-block;
    text-decoration: none;
    transition: color 0.2s;
    font-size: 100%;
    margin-bottom: 20%;
    text-align: left;
    align-items: left;
   
  }
  
  .bm-item:hover {
    color: rgb(255, 255, 255);
  }
  
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
  }
  
  .bm-burger-bars {
    background: #373a47;
  }
  
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  .bm-cross {
    background: #bdc3c7;
  }
  
  .bm-menu {
    background: #333;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  .bm-morph-shape {
    fill: #081e81;
  }
  
  .bm-item-list {
    color: #fafaf5;
  }
  
  .bm-overlay {
    background: rgba(214, 208, 208, 0.3);
  }

  